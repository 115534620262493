import { render, staticRenderFns } from "./trainingMatrix.vue?vue&type=template&id=6ec0e054&scoped=true&"
import script from "./trainingMatrix.js?vue&type=script&lang=js&"
export * from "./trainingMatrix.js?vue&type=script&lang=js&"
import style0 from "./docPrjt.less?vue&type=style&index=0&id=6ec0e054&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec0e054",
  null
  
)

export default component.exports