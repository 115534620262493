<template>
  <div>
    <h3 class="pan-title" style="text-align: left">
      部门列表
      <el-button type="primary" size="mini" class="rightFloat" @click="prjtDeptExport()">导出部门列表</el-button>
      <el-button class="el-icon-plus" v-if="amIPM" type="primary" size="mini"
                 @click="docDept = null; showDlg('editDocDeptDlg')"/>
      <el-button v-if="amIPM" type="primary" size="mini" :style="editing ? {}:{'background': '#8c939d','border':0}" @click="editing = !editing">编辑</el-button>
    </h3>
    <el-tree
        :data="deptTree"
        :props="defaultProps"
        default-expand-all
        :render-content="(h,{node,data})=>{return renderContent(h,{node,data},editing)}"
        :expand-on-click-node="false"
    >
    </el-tree>
    <editDocDeptDlg ref="editDocDeptDlg" :editDocDept="docDept" :prjtId="pid" :depts="depts" @oked="getDocPrjt();docDept = null"></editDocDeptDlg>
  </div>
</template>

<script>
import docPrjtMix from "@/doc/docPrjtMix";

export default {
  name: "docPrjtDepts",

  mixins: [docPrjtMix],

  data() {
    return {
      editing:false,
    }
  },

  methods:{
    renderContent(h, {node, data},e) {
      node.label_E = data.name_E
      return (
          (node.label == '全公司' || !e) ?
              <span>
            <span>{node.label + (node.label_E ? '(' + node.label_E + ')':'')}</span>
          </span> :
              <span>
            <span>{node.label + (node.label_E ? '(' + node.label_E + ')':'')}</span>
            <span style="padding-left:30px">
              <el-button size="mini" type="text" on-click={() => this.editDocDept(data)} class="el-icon-edit"/>
              <el-button size="mini" type="text" on-click={() => this.deleteDocDept(data)}
                         class="el-icon-delete"/>
            </span>
          </span>);
    }
  }
}
</script>

<style lang="less" src="./docPrjt.less" scoped/>