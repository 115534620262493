<template>
  <div>
    <h3 class="pan-title" style="padding-top: 30px">
      项目成员列表
      <el-button style="float: right" v-if="amIAdmin || amIPM" type="mini" @click="showDlg('addDocPrjtStaffDlg')">新增项目成员</el-button>
    </h3>
    <table class="table">
      <tr>
        <th>姓名</th>
        <th>角色</th>
        <th>操作</th>
      </tr>
      <tr v-for="it in prjtStaffs">
        <td>{{it.prjtStaffN}}</td>
        <td>{{it.role | DocRole}}</td>
        <td>
          <el-button type="mini" v-if="amIAdmin || amIPM" @click="removePrjtStaff(it.id)">移除</el-button>
        </td>
      </tr>
    </table>
    <addDocPrjtStaffDlg ref="addDocPrjtStaffDlg" :Id="pid" @oked="getDocPrjt()"></addDocPrjtStaffDlg>
  </div>
</template>

<script>
import docPrjtMix from "@/doc/docPrjtMix";

export default {
  name: "docPrjtStaffs",

  mixins:[docPrjtMix],
}
</script>

<style lang="less" src="./docPrjt.less" scoped/>