<template>
  <div class="right">
    <div class="user-box">
      <h3 class="head">项目详情
      </h3>
      <div style="text-align: left">
        <p>项目名称：<a>{{prjt.name}}</a><el-button type="mini" style="margin-left: 30px" @click="showDlg('editDocPrjtDlg')">更新信息</el-button>
          <el-button v-if="amIAdmin || amIPM" type="primary" size="mini" class="rightFloat" @click="goto('docPrjtRemarks/' + pid)">查看审阅意见</el-button>
        </p>
        <p>目标组织名称：<a>{{prjt.orgN}}</a></p>
        <p>描述：<a>{{prjt.des}}</a></p>
        <p>sop模板：<a>{{prjt.sopTpltN}}</a></p>
        <p>政策文件模板：<a>{{prjt.policyTpltN}}</a></p>
        <p>质量手册模板：<a>{{prjt.manualTpltN}}</a></p>
      </div>
    </div>
    <h3 class="pan-title" style="padding-top: 30px">
      文档数量统计
      <el-button v-if="amIPM" type="primary" size="mini" class="rightFloat" @click="prjtCensusExport()">导出项目统计Excel表
      </el-button>
    </h3>
    <table class="table">
      <tr>
        <th>序号</th>
        <th>文件类型</th>
        <th>总数量</th>
        <th>已定稿</th>
        <th>审阅中</th>
        <th>起草中</th>
      </tr>
      <tr v-for="(it, key, idx) in docCensus">
        <td>{{ key == 'all' ? '总计' : idx + 1 }}</td>
        <td>{{ key == 'all' ? '' : key | docType }}</td>
        <td>{{ it.total }}</td>
        <td>{{ it.fixed }}</td>
        <td>{{ it.remarking }}</td>
        <td>{{ it.draft }}</td>
      </tr>
    </table>

    <editDocPrjtDlg ref="editDocPrjtDlg" :oldDocPrjt="prjt" @oked="getDocPrjt()"></editDocPrjtDlg>
    <editAnnexDlg ref="editAnnexDlg" :prjtId="pid" :oldAnnex="annexe" @oked="getDocPrjt();annexe = null"></editAnnexDlg>
  </div>
</template>

<script>
import docPrjtMix from "@/doc/docPrjtMix";

export default {
  name: "docPrjt2",

  mixins:[docPrjtMix],
}
</script>

<style lang="less" src="./docPrjt.less" scoped/>
