import docPrjtMix from "@/doc/docPrjtMix";
import {cloneObj, convertToTitle, genMap} from "@/utils/xyhUtils";
import {DOC_TYPE} from "@/consts"
import Vue from 'vue'

export default {
    name: "trainingMatrix",

    mixins: [docPrjtMix],

    data() {
        return {
            deptMap: {},
            deptsMap:{},
        }
    },

    computed:{

        displayDocs(){
            let {docs, depts} = this
            docs.forEach(doc => {
                doc.typeN = this.DocTypesSMap[doc.type]
                if (doc.design) {
                    let deptMap = genMap(doc.design.deptsRecv, 'refId')
                    if (!doc.deptMap) Vue.set(doc, 'deptMap', deptMap)
                    for (let i in deptMap) {
                        if (!this.deptMap[i]) Vue.set(this.deptMap, i, doc.deptMap[i])
                    }
                    doc.design.deptsRecv.forEach(it => {
                        let roleMap = genMap(it.roles, 'refId')
                        Vue.set(it, 'roleMap', cloneObj(roleMap))
                        if (!this.deptMap[it.refId].roleMap) {
                            this.deptMap[it.refId].roleMap = {}
                        }
                        for (let i in genMap(it.roles, 'refId')) {
                            if (!this.deptMap[it.refId].roleMap[i]) Vue.set(this.deptMap[it.refId].roleMap, i, cloneObj(roleMap[i]))
                        }
                    })
                }
            })
            this.deptsMap = {}
            let map = genMap(depts)
            for(let i in this.deptMap){
                if(map[i] || i == 'allCompany'){
                    if(i != 'allCompany' && map[i].parentId) {
                        if(!this.deptsMap[map[i].parentId]) this.deptsMap[map[i].parentId] = {me:map[map[i].parentId], son:[]}
                        this.deptsMap[map[i].parentId].son.push(this.deptMap[i])
                    }else {
                        if(!this.deptsMap[i]) {
                            this.deptsMap[i] = {me: this.deptMap[i], son: []}
                        }else {
                            this.deptsMap[i].me = this.deptMap[i]
                        }
                    }
                }
            }
            docs = docs.filter(doc => doc.type == DOC_TYPE.sop || doc.type == DOC_TYPE.manual || doc.type == DOC_TYPE.policy)
            return docs
        },

    },

    methods:{

        handleDownload() {
            let {displayDocs, deptsMap} = this
            const {export_json_to_multiHeader_excel} = require('@/vendor/Export2Excel');
            let multiHeader =
                [
                    ['编号', '名称', '版本号', '归口部门',], //第一行
                    ['', '', '', '',] //第二行
                ] // 前两行的表头数据，二维数组，不够的用空白补全
            let filterVal = ['num', 'name', 'ver', 'deptN',]
            let docs = cloneObj(displayDocs)
            let roleIdx = 100
            for (let i in deptsMap){
                if(deptsMap[i].me.dept){
                    if(Object.keys(deptsMap[i].me.roleMap).length > 0) {
                        for (let j in deptsMap[i].me.roleMap) {
                            docs.forEach(doc => {
                                if (doc.deptMap && doc.deptMap[deptsMap[i].me.refId]?.roleMap && doc.deptMap[deptsMap[i].me.refId].roleMap[j]) {
                                    doc['r' + roleIdx] = 'x'
                                } else {
                                    doc['r' + roleIdx] = ''
                                }
                            })
                            filterVal.push('r' + roleIdx)
                            roleIdx++
                        }
                    }else {
                        docs.forEach(doc => {doc['r' + roleIdx] = ''})
                        filterVal.push('r' + roleIdx)
                        roleIdx++
                    }
                }
                deptsMap[i].son.forEach(it =>{
                    if(Object.keys(it.roleMap).length > 0){
                        for (let k in it.roleMap) {
                            docs.forEach(doc => {
                                if(doc.deptMap && doc.deptMap[it.refId]?.roleMap && doc.deptMap[it.refId].roleMap[k]){
                                    doc['r' + roleIdx] = 'x'
                                }else {
                                    doc['r' + roleIdx] = ''
                                }
                            })
                            filterVal.push('r' + roleIdx)
                            roleIdx++
                        }
                    }else {
                        docs.forEach(doc => {doc['r' + roleIdx] = ''})
                        filterVal.push('r' + roleIdx)
                        roleIdx++
                    }
                })
            }
            let data = this.formatJson(filterVal, docs)
            let merges = ['A1:A2', 'B1:B2', 'C1:C2', 'D1:D2',]// 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
            for (let i in deptsMap){
                if(deptsMap[i].me.roleMap){
                    let Length = Object.keys(deptsMap[i].me.roleMap).length
                    if (!Length) Length = 1
                    merges.push(convertToTitle(multiHeader[0].length + 1) + '1:' + convertToTitle(Length + multiHeader[0].length) + '1')
                    multiHeader[0].push(deptsMap[i].me.dept)
                    multiHeader[0] = multiHeader[0].concat(new Array(Length - 1).fill(''))
                    if(Object.keys(deptsMap[i].me.roleMap).length > 0) {
                        for (let k in deptsMap[i].me.roleMap) {
                            multiHeader[1].push(deptsMap[i].me.roleMap[k].role)
                        }
                    }else {
                        multiHeader[1].push('')
                    }
                }
                for (let j in deptsMap[i].son) {
                    let Length = Object.keys(deptsMap[i].son[j].roleMap).length
                    if (!Length) Length = 1
                    merges.push(convertToTitle(multiHeader[0].length + 1) + '1:' + convertToTitle(Length + multiHeader[0].length) + '1')
                    multiHeader[0].push((deptsMap[i].me.dept ? deptsMap[i].me.dept:deptsMap[i].me.name) + '/' + deptsMap[i].son[j].dept)
                    multiHeader[0] = multiHeader[0].concat(new Array(Length - 1).fill(''))
                    if(Object.keys(deptsMap[i].son[j].roleMap).length > 0){
                        for (let k in deptsMap[i].son[j].roleMap) {
                            multiHeader[1].push(deptsMap[i].son[j].roleMap[k].role)
                        }
                    }else {
                        multiHeader[1].push('')
                    }
                }
            }
            let filename = '培训矩阵'
            export_json_to_multiHeader_excel({
                multiHeader,
                filename,
                merges,
                data
            })
        },

        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                return v[j]
            }))
        },
    },
}