import REQ from "@/app/REQ";
import docPrjt from "@/doc/docPrjt2.vue"
import docPrjtStaffs from "@/doc/docPrjtStaffs.vue"
import docPrjtRoles from "@/doc/docPrjtRoles.vue"
import docPrjtDepts from "@/doc/docPrjtDepts.vue"
import docPrjtDocs from "@/doc/docPrjtDocs.vue"
import trainingMatrix from "@/doc/trainingMatrix.vue";
import {classifyByProp, buildTree, genMap, invMapOf, upWFoundById, collectLinked} from "../utils/xyhUtils"
import {DOC_ROLE, DOC_STATE, DOC_TYPE} from "@/consts"
import {mapGetters, mapState} from "vuex";
import docPrjtEditState from "@/doc/docPrjtEditState";

export default {
    name: 'docPrjtTabs',
    components:{
        docPrjt,
        docPrjtDocs,
        docPrjtDepts,
        docPrjtRoles,
        docPrjtStaffs,
        trainingMatrix,
    },
    props: ['pid'],

    data() {
        return {
            tabs: [
                {name: 'docPrjt', label: '基本信息',},
                {name: 'docPrjtDepts', label: '部门配置',},
                {name: 'docPrjtRoles', label: '角色配置',},
                {name: 'docPrjtDocs', label: '文档列表',},
                {name: 'docPrjtStaffs', label: '项目成员',},
                {name: 'trainingMatrix', label: '培训矩阵', power: true},
            ],

            tabN: 'docPrjt',
            prjt: {},
        }
    },

    provide:function () {
        return {
            getDocPrjt:this.getDocPrjt,
            createDocCensus:this.createDocCensus,
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(from.name == 'docTabs') vm.tabN = 'docPrjtDocs'
        });
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
            orgName: ({ curOrg }) => curOrg?.sname || curOrg?.name ,
            myId: ({curStaff}) => curStaff?.id,
        }),
        ...mapGetters(['amIAdmin']),
    },

    created() {
        docPrjtEditState.clean()
        this.getDocPrjt()
    },

    methods: {
        getDocPrjt() {
            let {pid} = this
            this.req2Data(REQ.docPrjt, pid, null,({prjtStaffs,staffs,docs,tplts,docStaffs,prjt,depts,roles}) => {
                prjt.sopTpltN = tplts.find(it=>it.id == prjt.sopTpltId) ? tplts.find(it=>it.id == prjt.sopTpltId).name : ''
                prjt.policyTpltN = tplts.find(it=>it.id == prjt.policyTpltId) ? tplts.find(it=>it.id == prjt.policyTpltId).name : ''
                prjt.manualTpltN = tplts.find(it=>it.id == prjt.manualTpltId) ? tplts.find(it=>it.id == prjt.manualTpltId).name : ''
                upWFoundById(docStaffs, staffs, "staffId", (docStaff, staff) => docStaff.staffN = staff.name)
                upWFoundById(roles, depts, 'deptId', (role,dept)=>role.deptN = dept.name)
                roles.forEach(role => {
                    if (!role.deptN) role.deptN = '全公司'
                    if (!roles.uperN) role.uperN = ''
                })
                let docIdOfStaffs = classifyByProp(docStaffs, 'docId')
                docs.forEach(doc=>{
                    let staffs = docIdOfStaffs[doc.id]
                    if(staffs){
                        let authors = staffs.filter(it=>it.role == DOC_ROLE.author)
                        let auditors = staffs.filter(it=>it.role == DOC_ROLE.auditor)
                        let translators = staffs.filter(it=>it.role == DOC_ROLE.translator)
                        let allStaffs = Object.values(genMap(staffs, 'staffId'))
                        doc.amIStaff = staffs.find(it=>it.staffId == this.myId) ? true:false
                        doc.amIAuthor = authors.find(it=>it.staffId == this.myId) ? true:false
                        doc.amITranslator = translators.find(it=>it.staffId == this.myId) ? true:false
                        doc.authorN = authors.map(it=>it.staffN).join('、')
                        doc.auditorN = auditors.map(it=>it.staffN).join('、')
                        doc.translatorN = translators.map(it=>it.staffN).join('、')
                        doc.allStaffN = allStaffs.map(it=>it.staffN).join('、')
                    }
                })
                upWFoundById(prjtStaffs,staffs,'staffId',(prjtStaff,staff)=>prjtStaff.prjtStaffN = staff.name)
                docPrjtEditState.amIPrjtStaff = prjtStaffs.find(it=>it.staffId == this.myId) ? true:false
                let amIPM = prjtStaffs.find(it=>it.role == DOC_ROLE.PM && it.staffId == this.myId) ? true:false
                this.tabs[this.tabs.length-1].power = !amIPM
                docPrjtEditState.amIPM = amIPM
                docPrjtEditState.amIAuthor = prjtStaffs.find(it=>it.role == DOC_ROLE.author && it.staffId == this.myId) ? true:false
                upWFoundById(docs,tplts,'tpltId',(doc,tplt)=>doc.tpltN = tplt.name)
                upWFoundById(docs, depts, 'deptId', (doc, dept)=> {
                    doc.deptN = dept.name
                    doc.deptN_E = dept.name_E ? dept.name_E:''
                })
                docs.forEach(it=> {
                    if(!it.type) it.type = DOC_TYPE.sop
                    if(!it.state) it.state = DOC_STATE.remarking
                    it.canPreview = it.tpltId || (it.type == DOC_TYPE.sop && prjt.sopTpltId) || (it.type == DOC_TYPE.policy && prjt.policyTpltId) || (it.type == DOC_TYPE.manual && prjt.manualTpltId)
                    it.url = ((it.type == DOC_TYPE.annex || it.type == DOC_TYPE.file) && it.file) ? it.file:XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                })
                upWFoundById(roles, staffs, 'uper', (role,staff) => role.uperN = staff.name)
                let deptMap = genMap(depts)
                depts.forEach(dept => {
                   dept.parentN = dept.parentId ? deptMap[dept.parentId].name:'全公司'
                })
                let deptTree = [{name:'全公司', name_E: 'allCompany', children:buildTree(depts, 'parentId')}]
                depts = collectLinked(depts, 'parentId', 'childrens')
                for (let i in prjt){this.$set(docPrjtEditState.prjt,i,prjt[i])}
                Object.assign(docPrjtEditState.docCensus, this.createDocCensus(docs))
                docPrjtEditState.docs.splice(0, docPrjtEditState.docs.length, ...docs)
                docPrjtEditState.roles.splice(0, docPrjtEditState.roles.length, ...roles)
                docPrjtEditState.depts.splice(0, docPrjtEditState.depts.length, ...depts)
                docPrjtEditState.deptTree.splice(0, docPrjtEditState.deptTree.length, ...deptTree)
                docPrjtEditState.prjtStaffs.splice(0, docPrjtEditState.prjtStaffs.length, ...prjtStaffs)
                docPrjtEditState.staffs.splice(0, docPrjtEditState.staffs.length, ...staffs)
            })
        },

        createDocCensus(docs){
            let docCensus = {all:{},}
            if(this.orgName.includes('国信')){
                docs.forEach(doc => {
                    doc.typeN = doc.num.substring(0, doc.num.indexOf("-")) || doc.num
                    if(!docCensus[doc.typeN]) docCensus[doc.typeN] = {}
                })
            }else {
                docCensus = {sop: {}, policy: {}, manual: {}, annex: {}, file: {}, all:{},}
                docs.forEach(doc => doc.typeN = doc.type)
            }
            let docState = invMapOf(DOC_STATE)
            for (let i in docCensus) {
                docCensus[i].total = 0
                for (let j in DOC_STATE) {
                    docCensus[i][j] = 0
                }
            }
            docCensus.all.total = docs.length
            docs.forEach(doc => {
                docCensus[doc.typeN].total++
                docCensus[doc.typeN][docState[doc.state]]++
                docCensus.all[docState[doc.state]]++
            })
            return docCensus
        },
    },

}
