<template>
  <div>
    <h3 class="pan-title">
      文档中使用角色列表
      <el-input style="float:left;width: 200px" v-model="filterWord1" @change="search">
        <i class="el-icon-search el-input__icon" slot="suffix" @click="search"></i>
      </el-input>
      <el-button type="primary" size="mini" class="rightFloat" @click="prjtRoleExport()">导出角色列表</el-button>
      <el-button v-if="amIPM || amIAuthor" type="primary" size="mini" class="el-icon-plus" @click="editRole()"/>
    </h3>
    <table class="table">
      <tr>
        <th>中文名</th>
        <th>英文名</th>
        <th>中文描述</th>
        <th>英文描述</th>
        <th>所属部门</th>
        <th>添加者</th>
        <th>操作</th>
      </tr>
      <tr v-for="role in displayRoles">
        <td>{{role.name}}</td>
        <td>{{role.name_E}}</td>
        <td>{{role.des}}</td>
        <td>{{role.des_E}}</td>
        <td>{{role.deptN || '全公司'}}</td>
        <td>{{role.uperN}}</td>
        <td>
          <a  v-if="amIPM || amIAuthor" size="mini" class="opBtn el-icon-edit" @click="editRole(role)"/>
          <a v-if="amIPM" size="mini" class="opBtn el-icon-delete" @click="editRole(role, true)"/>
        </td>
      </tr>
    </table>
    <el-dialog title="添加文档中使用角色" v-el-drag-dialog append-to-body :visible.sync="roleDlg" :modal="false">
      <el-form>
        <el-form-item label="中文名称">
          <el-input style="width: 75%;float: left" v-model="role.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input style="width: 75%;float: left" v-model="role.name_E" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="中文描述">
          <el-input style="width: 75%;float: left" v-model="role.des" autocomplete="off" type="textarea" row="2"></el-input>
        </el-form-item>
        <el-form-item label="英文描述">
          <el-input style="width: 75%;float: left" v-model="role.des_E" autocomplete="off" type="textarea" row="2"></el-input>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-select style="float: left" v-model="role.deptId" placeholder="部门">
            <el-option label="全公司" value="allCompany"></el-option>
            <el-option v-for="dept in depts" :label="dept.name" :value="dept.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <dlgFoot slot="footer" @ok="editDocCrRole" @cancel="roleDlg=false;"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import docPrjtMix from "@/doc/docPrjtMix";

export default {
  name: "docPrjtRoles",

  mixins: [docPrjtMix],
}
</script>

<style lang="less" src="./docPrjt.less" scoped/>