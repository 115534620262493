import Vue from "vue";


const docPrjtEditState = Vue.observable({
    prjt: {},
    docs: [],
    amIPM: false,
    amIAuthor: false,
    amIPrjtStaff: false,
    roles: [],
    depts: [],
    deptTree: [],
    staffs: [],
    prjtStaffs: [],
    docCensus: {},
    order: {
        name: false,
        num: true,
    },
    filterProp: {
        dept: null,
        docType: 'unAnnex',
        docStatus: '',
        roleN: 'authorN',
    },

    clean() {
        this.prjt = {}
        this.docs = []
        this.amIPM = false
        this.amIAuthor = false
        this.amIPrjtStaff = false
        this.roles = []
        this.depts = []
        this.deptTree = []
        this.staffs = []
        this.prjtStaffs = []
        this.docCensus = {}
    }
})

export default docPrjtEditState
