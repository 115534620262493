<template>
  <div>
    <h3 class="pan-title">
      <p>文档列表</p>
      <div style="padding-top: 20px">
        <el-input style="float:left;width: 200px" v-model="filterWord1" @change="search">
          <i class="el-icon-search el-input__icon" slot="suffix" @click="search"></i>
        </el-input>
        <el-button type="primary" size="mini" class="rightFloat"
                   @click="prjtDocExport((!filterProp.docType || filterProp.docType == 'unAnnex') ?
                   (!filterProp.docType ? '全部类型':'非附件'):DocTypesSMap[filterProp.docType],
                   filterProp.docStatus ? DocStatesSMap[filterProp.docStatus]:'全部状态',)">
          导出文档Excel表
        </el-button>
        <el-dropdown v-if="amIPM || amIAuthor" class="rightFloat" @command="addDoc" placement="'bottom'">
          <span class="el-dropdown-link">
            <el-button type="primary" size="mini" class="el-icon-plus"/>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item style="width: 80px" v-for="it in DocTypes" :command="it.id">{{ it.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </h3>
    <table class="table">
      <tr>
        <th>序号</th>
        <th>文档编号
          <el-button :class="sortingProperty == 'num' ? 'sorting':'unSorting'" type="mini" @click="sorted('num')" :icon="'el-icon-' + (order.num ? 'top':'bottom')"/>
        </th>
        <th>名称
          <el-button :class="sortingProperty == 'name' ? 'sorting':'unSorting'" type="mini" @click="sorted('name')" :icon="'el-icon-' + (order.name ? 'top':'bottom')"/>
        </th>
        <th><p>归口部门</p>
          <el-dropdown style="width: auto" @command="deptFilter" placement="'bottom'">
                <span class="el-dropdown-link">
                  <i class="widthLimit filterWordCol">{{filterProp.dept ? filterProp.dept.name:'全部'}}</i>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="it in depts" style="min-width: 200px" :command="it">{{ it.name }}</el-dropdown-item>
              <el-dropdown-item :command="null">全部</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </th>
        <th><p>类型</p>
          <el-dropdown @command="typeFilter" placement="'bottom'">
                <span class="el-dropdown-link">
                  <span class="filterWordCol" v-if="!filterProp.docType || filterProp.docType == 'unAnnex'">{{!filterProp.docType ? '全部类型':'非附件'}}</span>
                  <span class="filterWordCol" v-else>{{filterProp.docType | DocType}}</span>
                  <i class="el-icon-arrow-down el-icon--right">
                    </i>
                </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="width: 80px" v-for="it in DocTypes" :command="it.id">{{ it.name }}</el-dropdown-item>
              <el-dropdown-item :command="'unAnnex'">非附件</el-dropdown-item>
              <el-dropdown-item :command="null">全部</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </th>
        <th><p>状态</p>
          <el-dropdown @command="statusFilter" placement="'bottom'">
                <span class="el-dropdown-link">
                  <span class="filterWordCol" v-if="filterProp.docStatus">{{filterProp.docStatus | DocState}}</span>
                  <span class="filterWordCol" v-else>全部</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="width: 80px" v-for="it in DocStates" :command="it.id">{{ it.name }}</el-dropdown-item>
              <el-dropdown-item :command="null">全部</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </th>
        <th>版本号</th>
        <th><p>人员</p>
          <el-dropdown @command="roleFilter" placement="'bottom'">
                <span class="el-dropdown-link">
                  <span class="filterWordCol">{{filterOfRole[filterProp.roleN]}}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="width: 80px" v-for="(val,key) in filterOfRole" :command="key">{{ val }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </th>
        <th>操作</th>
        <th v-if="isBatch"><el-checkbox class="checkbox" :indeterminate="isIndeterminate"  :value="allSeled" @change="batchSelection">全选</el-checkbox></th>
      </tr>
      <template v-for="(item, idx) in displayDocs">
        <tr v-if="item.state == DOC_STATE.draft && (amIPM || item.amIAuthor || item.amITranslator)">
          <td>{{idx+1}}</td>
          <td>
            <router-link style="color: #409eff;overflow: hidden" :to="'/main/docTabs/'+ item.id">{{item.num}}</router-link>
          </td>
          <td><p class="omit">{{item.name}}</p><p class="omit">{{item.name_E}}</p></td>
          <td><p class="omit">{{item.deptN}}</p><p class="omit">{{item.deptN_E}}</p></td>
          <td>{{item.type||DOC_TYPE.sop | DocType}}</td>
          <td>{{item.state | DocState}}</td>
          <td>{{item.ver}}</td>
          <td style="width: 15%">
            <el-tooltip placement="top"><div slot="content">{{ item[filterProp.roleN] }}</div><p>{{ item[filterProp.roleN] }}</p></el-tooltip>
          </td>
          <td>
            <a class="opBtn" v-if="(amIPrjtStaff || item.amIStaff) && (item.canPreview || ((item.type == DOC_TYPE.annex || item.type == DOC_TYPE.file) && item.file))" style="cursor: pointer;" @click="preview(item.url)">预览</a>
            <a class="opBtn el-icon-download" v-if="(amIPrjtStaff || item.amIStaff) && item.file" @click="downloadFile(item.num+'_'+item.name,item.file)"/>
            <router-link v-if="item.type != DOC_TYPE.annex && item.type != DOC_TYPE.file && (amIPM || item.amIStaff)" :class="['opBtn',(amIPM || item.amIAuthor || item.amITranslator) ? 'el-icon-edit-outline':'el-icon-view']" :to="'/main/docTabs/'+ item.id"/>
            <a class="opBtn el-icon-edit" v-if="amIPM || item.amIAuthor" @click="editDoc(item,item.type)"/>
            <a class="opBtn el-icon-delete" v-if="amIPM && isBatch" @click="planRmDoc = item;showDlg('rmDocDlg')"/>
          </td>
          <td><el-checkbox v-if="isBatch" v-model="checks[item.id]"></el-checkbox></td>
        </tr>
        <tr v-if="item.state != DOC_STATE.draft">
          <td>{{idx+1}}</td>
          <td>
            <router-link style="color: #409eff;overflow: hidden" :to="'/main/docTabs/'+ item.id">{{item.num}}</router-link>
          </td>
          <td><p class="omit">{{item.name}}</p><p class="omit">{{item.name_E}}</p></td>
          <td><p class="omit">{{item.deptN}}</p><p class="omit">{{item.deptN_E}}</p></td>
          <td>{{item.type||DOC_TYPE.sop | DocType}}</td>
          <td>{{item.state | DocState}}</td>
          <td>{{item.ver}}</td>
          <td style="width: 15%">
            <el-tooltip placement="top"><div slot="content">{{ item[filterProp.roleN] }}</div><p>{{ item[filterProp.roleN] }}</p></el-tooltip>
          </td>
          <td>
            <a class="opBtn" v-if="(amIPrjtStaff || item.amIStaff) && (item.canPreview || ((item.type == DOC_TYPE.annex || item.type == DOC_TYPE.file) && item.file))" style="cursor: pointer;" @click="preview(item.url)">预览</a>
            <a class="opBtn el-icon-download" v-if="(amIPrjtStaff || item.amIStaff) && item.file" @click="downloadFile(item.num+'_'+item.name,item.file)"/>
            <router-link v-if="item.type != DOC_TYPE.annex && item.type != DOC_TYPE.file && (amIPM || item.amIStaff)" :class="['opBtn',(item.state != DOC_STATE.fixed && (amIPM || item.amIAuthor || item.amITranslator)) ? 'el-icon-edit-outline':'el-icon-view']" :to="'/main/docTabs/'+ item.id"/>
            <a class="opBtn el-icon-edit" v-if="item.state != DOC_STATE.fixed && (amIPM || item.amIAuthor)" @click="editDoc(item,item.type)"/>
            <a class="opBtn el-icon-delete" v-if="amIPM && isBatch" @click="planRmDoc = item;showDlg('rmDocDlg')"/>
          </td>
          <td><el-checkbox v-if="isBatch" v-model="checks[item.id]"></el-checkbox></td>
        </tr>
      </template>
    </table>
    <el-button v-if="isBatch" type="primary" style="float: right;margin-right: 20px" @click="showDlg('addDocStaffDlg')">人员分配</el-button>
    <el-button v-if="isBatch" type="primary" style="float: right;margin-right: 20px" @click="showDlg('selDeptDlg');targetPrjtId = ''">部门分配</el-button>
    <el-button v-if="isBatch" type="primary" style="float: right;margin-right: 20px" @click="getMyPrjts()">项目间转移</el-button>
    <el-button v-if="amIAdmin || amIPM" type="primary" style="float: right;margin-right: 20px" @click="isBatch=!isBatch">
      {{(isBatch? '关闭':'') + '管理' }}</el-button>

    <el-dialog title="选择目标项目" :visible.sync="switchPrjt" width="30%">
      <el-select v-model="targetPrjtId" placeholder="请选择目标项目">
        <el-option v-for="it in myPrjts" :key="it.id" v-if="it.id != pid" :label="it.sname || it.name" :value="it.id"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
    			<el-button @click="switchPrjt = false">取 消</el-button>
    			<el-button type="primary" @click="transferDoc">确 定</el-button>
  			</span>
    </el-dialog>
    <rmDocDlg ref="rmDocDlg" :doc="planRmDoc" @oked="getDocPrjt();planRmDoc = {};ok('删除成功')"></rmDocDlg>
    <editDocDlg ref="editDocDlg" :depts="depts" :pId="pid" :oldDoc="oldDoc" :typeN="typeN" :type="type" @oked="getDocPrjt"></editDocDlg>
    <addDocStaffDlg ref="addDocStaffDlg" @oked="batchAddDocStaff"/>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="transferDoc"></selDeptDlg>
  </div>
</template>

<script>
import docPrjtMix from "@/doc/docPrjtMix";

export default {
  name: "docPrjtDocs",

  mixins: [docPrjtMix],
}
</script>

<style lang="less" src="./docPrjt.less" scoped/>