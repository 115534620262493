import { render, staticRenderFns } from "./docPrjtTabs.vue?vue&type=template&id=a55c43ac&scoped=true&"
import script from "./docPrjtTabs.js?vue&type=script&lang=js&"
export * from "./docPrjtTabs.js?vue&type=script&lang=js&"
import style0 from "./docPrjt.less?vue&type=style&index=0&id=a55c43ac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a55c43ac",
  null
  
)

export default component.exports